import React from 'react';
import './Skills.css';
// Import custom icons
import phpIcon from '../assets/php.svg';
import pythonIcon from '../assets/python.svg';
import nodejsIcon from '../assets/nodejs.svg';
import csharpIcon from '../assets/csharp.svg';
import javaIcon from '../assets/java.svg';
import reactIcon from '../assets/react.svg';
import nextIcon from '../assets/next.svg';
import angularIcon from '../assets/angular.svg';
import aspnetIcon from '../assets/net.svg';
import vueIcon from '../assets/vue.svg';
import mongodbIcon from '../assets/mongodb.svg';
import mysqlIcon from '../assets/mysql.svg';
import graphqlIcon from '../assets/graphql.svg';
import sqliteIcon from '../assets/sqlite.svg';

const Skills = () => {
  const backendSkills = [
    { name: "Java", icon: javaIcon },
    { name: "C#", icon: csharpIcon },
    { name: "NodeJS", icon: nodejsIcon, proficiency: true },
    { name: "PHP", icon: phpIcon },
    { name: "Python", icon: pythonIcon },
  ];
  const frontendSkills = [
    { name: "React", icon: reactIcon, proficiency: true },
    { name: "NextJS", icon: nextIcon },
    { name: "Angular", icon: angularIcon, proficiency: true },
    { name: "ASP.NET", icon: aspnetIcon },
    { name: "VueJS", icon: vueIcon },
  ];
  const databaseSkills = [
    { name: "MongoDB", icon: mongodbIcon, proficiency: true },
    { name: "MySQL", icon: mysqlIcon },
    { name: "GraphQL", icon: graphqlIcon },
    { name: "SQLite", icon: sqliteIcon },
  ];

  return (
    <section id="skills" className="skills" >
      <h2 className="skills-header">My Skills</h2>
      <div className="skills-container">
        <div className="skills-box">
          <h3>Backend Development</h3>
          <ul>
            {backendSkills.map(skill => (
              <li key={skill.name} className={skill.proficiency ? 'proficient' : ''}>
                <img src={skill.icon} alt={skill.name} className="custom-icon" />
                {skill.name}
                {skill.proficiency && <div className="proficiency-line"></div>}
              </li>
            ))}
          </ul>
        </div>
        <div className="skills-box">
          <h3>Frontend Development</h3>
          <ul>
            {frontendSkills.map(skill => (
              <li key={skill.name} className={skill.proficiency ? 'proficient' : ''}>
                <img src={skill.icon} alt={skill.name} className="custom-icon" />
                {skill.name}
                {skill.proficiency && <div className="proficiency-line"></div>}
              </li>
            ))}
          </ul>
        </div>
        <div className="skills-box">
          <h3>Database</h3>
          <ul>
            {databaseSkills.map(skill => (
              <li key={skill.name} className={skill.proficiency ? 'proficient' : ''}>
                <img src={skill.icon} alt={skill.name} className="custom-icon" />
                {skill.name}
                {skill.proficiency && <div className="proficiency-line"></div>}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="proficiency">
        Proficiency
      </div>
    </section>
  );
};

export default Skills;

import React from 'react';
import './Header.css';
import { FaGithub, FaLinkedin, FaEnvelope, FaFilePdf } from 'react-icons/fa';
import resume from '../assets/onur_updating.pdf'; // Ensure your resume PDF is in the specified path

const Header = () => {
  return (
    <header className="header">
      <div className="left-side">
        <a href="https://github.com/ozXoz" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
        <a href="https://www.linkedin.com/in/onur-korkmaz/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href="mailto:honourkorkmaz@gmail.com">
          <FaEnvelope />
        </a>
        <a href={resume} download>
          <FaFilePdf />
        </a>
      </div>
      <div className="right-side">
        <nav>
          <ul className="nav-list">
            <li><a href="#skills">Skills</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

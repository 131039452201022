import React from 'react';
import './Profile.css';
import profilePicture from '../assets/profile-picture.jpg'; // Ensure this path is correct
import trioTechInvoiceLogo from '../assets/TrioTechInvoice_transparent.png';
import trioTechChatLogo from '../assets/TrioTechChat_transparent.png';
import trioTechShopLogo from '../assets/TrioTechShop_transparent.png';
import invertoryCheckManagement from '../assets/Management_System_transparent.png';
import sfClient from '../assets/SF_transparent.png';
import jtCleint from '../assets/JT_transparent.png';
import rwClient from '../assets/RW_transparent.png';
import bookingSystem from '../assets/Booking_System_transparent.png';
import productManagementSystem from '../assets/Product_Management_transparent.png';

const Profile = () => {
  return (
    <section id="profile" className="profile">
      <div className="profile-container">
        <h1>I'm Oz Onur</h1>
        <div className="profile-picture">
          <img src={profilePicture} alt="Profile" />
        </div>
        <div className="backit">
         
          <h2>Full Stack Developer</h2>
        </div>
        <p className="profile-description">
        With a wealth of experience,
        </p>
        <p className="profile-description">
          I've empowered 50+ freelancers for my clients
        </p>
        <p className="profile-description">
          Creating stunning designs
        </p>
        <p className="profile-description">
          Robust full stack solutions that make a real impact.
        </p>
        <div className="logos-container">
          <a href="https://www.trioteachinvoice.today" target="_blank" rel="noopener noreferrer">
            <img src={trioTechInvoiceLogo} alt="TrioTech Invoice" className="logo" />
          </a>
          <a href="https://www.triotech.live/" target="_blank" rel="noopener noreferrer">
            <img src={trioTechChatLogo} alt="TrioTech Chat" className="logo" />
          </a>
          <a href="https://www.triotech.shop" target="_blank" rel="noopener noreferrer">
            <img src={trioTechShopLogo} alt="TrioTech Shop" className="logo" />
          </a>
          <a href="https://drive.google.com/file/d/17DhMHtPYxw_RbDWMrejqpZp31B6FPDfi/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            <img src={invertoryCheckManagement} alt="Inventory Management" className="logo" />
          </a>
          <a href="https://client-saf1-hmet-portfolio.vercel.app/" target="_blank" rel="noopener noreferrer">
            <img src={sfClient} alt="sf Client" className="logo" />
          </a>
          <a href="https://j3y-c1.vercel.app/" target="_blank" rel="noopener noreferrer">
            <img src={jtCleint} alt="jt Client" className="logo" />
          </a>
          <a href="https://robert-wemigwans-portfolio.vercel.app/" target="_blank" rel="noopener noreferrer">
            <img src={rwClient} alt="rw Client" className="logo" />
          </a>
          <a href="https://booking-site-main-2.vercel.app/" target="_blank" rel="noopener noreferrer">
            <img src={bookingSystem} alt="Booking System" className="logo" />
          </a>
          <a href="https://frontend-three-ruddy-21.vercel.app/" target="_blank" rel="noopener noreferrer">
            <img src={productManagementSystem} alt="Product Management System" className="logo" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Profile;

// Projects.js
import React, { useState } from 'react';
import './styles.css'; // Import the CSS file

const projects = [
  {
    title: "E-commerce Platform",
    description: "A full-featured e-commerce platform.",
    image: "https://i.ibb.co/W5DL9zp/ecom-img.png",
    technologies: ["React", "Node.js", "MongoDB", "CSS", "Bootstrap","StripeJS"],
    category: "Full Stack",
    github: "https://github.com/ozXoz",
    website: "https://www.triotech.shop/"
  },
  {
    title: "Oz E-commerce Platform",
    description: "A complete e-commerce solution.",
    image: "https://i.ibb.co/qsC09zz/Screenshot-2024-06-01-at-7-11-34-PM.png",
    technologies: ["React", "Node.js", "MongoDB", "CSS", "Bootstrap","StripeJS"],
    category: "Full Stack",
    github: "https://github.com/ozXoz",
    website: "https://ecommerce-blue-eight.vercel.app/"
  },
  {
    title: " Bussiness  ERM System",
    description: "An Enterprise Resource Planning system.",  
    image: "https://i.ibb.co/zrNHbfH/erm-img.png",
    technologies: ["React", "Node.js", "MongoDB", "CSS", "Bootstrap"],
    category: "Full Stack",
    github: "https://github.com/ozXoz",
    website: "https://www.trioteachinvoice.today/"
  
  },
  {
    title: " MERN Chat Application",
    description: "A real-time chat application.",
        image: "https://i.ibb.co/7R5x3RY/chat-img.png",
    technologies: ["React", "Node.js", "MongoDB", "CSS", "Bootstrap",""],
    category: "Full Stack",
    github: "https://github.com/ozXoz",
    website: "https://www.triotech.live/"
  },
  {
    title: "Employee Management System - Arduino Test",
    description: "An employee management system with Arduino tests.",
        image: "https://i.ibb.co/5v0vkp0/Screenshot-2024-06-01-at-7-02-13-PM.png",
    technologies: ["React", "TypeScript", "Node.js", "MongoDB", "CSS", "Bootstrap"],
    category: "Full Stack",
    github:"https://github.com/ozXoz",
    website: "https://frontend-three-ruddy-21.vercel.app/"
  },
  
  {
    title: "Online Tutoring Website",
    description: "A comprehensive online tutoring platform.",
          image: "https://i.ibb.co/R3NvLwQ/about-page.png",
    technologies: ["React", "API", "Node.js", "MongoDB","ZegoExpress Engine"],
    category: "Volunteer",
    github: "https://github.com/ozXoz/online-tutorial",
    website: "https://github.com/ozXoz/online-tutorial"
  },

  {
    title: "Car Finance - Lease Calculator",
    description: "A calculator for estimating car lease costs.",
        image: "https://i.ibb.co/VvKPKmK/Screenshot-2024-06-01-at-7-53-19-PM.png",
    technologies: ["React", "API", "Node.js", "MongoDB","ZegoExpress Engine"],
    category: "Volunteer",
    github: "https://github.com/ozXoz",
    website: "https://finance-and-leasin-car-calculator.vercel.app/"
  },
  
  {
    title: "Hey Hey Misson List",
    description: "A mission list application.",
          image: "https://i.ibb.co/MP1q17p/Screenshot-2024-06-01-at-7-22-46-PM.png",
    technologies: ["Angular", "API", "Node.js", "MongoDB"],
    category: "Volunteer",
    github: "https://github.com/ozXoz",
    website: "https://mission-eta.vercel.app/"
    
  },

  {
    title: "App Weather",
    description: "A weather application with real-time updates.",
        image: "https://i.ibb.co/0CR96KV/Screenshot-2024-06-01-at-7-28-19-PM.png", // Replace with actual image URL
    technologies: ["React", "Weather API"],
    category: "Volunteer",
    github: "https://github.com/ozXoz",
    website: "https://weather-x-one.vercel.app/"
  },

  {
  title: "EmployeeXpress - CRUD Employee Application",
  description: "A CRUD application for employee management.",
    image: "https://i.ibb.co/Qk2bFNk/Screenshot-2024-06-01-at-8-00-35-PM.png", // Replace with actual image URL
  technologies: ["Spring Boot", "React", "MongoDB"],
  category: "Backend",
  github: "https://github.com/ozXoz",
  
},
{
  title: "Hotel Booking System",
  description: "A hotel booking system.",
    image: "https://i.ibb.co/F45z3nd/Screenshot-2024-06-01-at-8-25-42-PM.png", // Replace with actual image URL
  technologies: ["Node.js", "Angular (TypeScript)", "MongoDB"],
  category: "Backend",
  github: "https://github.com/ozXoz",
  website: "https://booking-site-main-2.vercel.app/"
}
,

{
  title: "Inventory Management System",
  description: "A system to manage inventory.",
    image: "https://i.ibb.co/g4Jt541/Screenshot-2024-06-01-at-8-11-42-PM.png",
  technologies: [ "PHP", "MySQL", "Bootstrap"],
  category: "Backend",
  github: "https://drive.google.com/file/d/17DhMHtPYxw_RbDWMrejqpZp31B6FPDfi/view?usp=sharing)",
  website: "https://drive.google.com/file/d/17DhMHtPYxw_RbDWMrejqpZp31B6FPDfi/view?usp=sharing)"
},

{
  title: "Admin Panel",
  description: "An admin panel for efficient management.",
    image: "https://i.ibb.co/9GRBwQ8/Screenshot-2024-06-01-at-8-16-39-PM.png", // Replace with actual image URL
  technologies: ["Laravel (PHP)", "SQL"],
  category: "Backend",
  github: "https://drive.google.com/file/d/17DhMHtPYxw_RbDWMrejqpZp31B6FPDfi/view?usp=sharing)",
  website: "https://drive.google.com/file/d/17DhMHtPYxw_RbDWMrejqpZp31B6FPDfi/view?usp=sharing)"
},


  {
    title: "Client I",
    description: "A frontend project for a client's website.",
        image: "https://i.ibb.co/3TstgRs/Screenshot-2024-06-01-at-7-42-46-PM.png", // Replace with actual image URL
    technologies: ["React", "Bootstrap", "Tailwind CSS", "EmailJS"],
    category: "Frontend Developer",
    github: "https://github.com/ozXoz",
    website: "https://client-saf1-hmet-portfolio.vercel.app/"
  },
  {
    title: "Client II",
    description: "A frontend project for a client's website.",
        image: "https://i.ibb.co/JdCxqJj/Screenshot-2024-06-01-at-7-42-36-PM.png", // Replace with actual image URL
    technologies: ["React", "Bootstrap", "Tailwind CSS", "EmailJS"],
    category: "Frontend Developer",
    github: "https://github.com/ozXoz",
    website: "https://j3y-c1.vercel.app/"
  },
  {
    title: "Client III",
    description: "A frontend project for a client's website.",
        image: "https://i.ibb.co/Y7FZjmD/Screenshot-2024-06-01-at-7-42-28-PM.png", // Replace with actual image URL
    technologies: ["React", "Bootstrap", "Tailwind CSS", "EmailJS"],
    category: "Frontend Developer",
    github: "https://github.com/ozXoz",
    website: "https://robert-wemigwans-portfolio.vercel.app/"
  },
  // Add more projects here
];

const Projects = () => {
  const [filter, setFilter] = useState('All');

  const filteredProjects = filter === 'All' ? projects : projects.filter(project => project.category === filter);

  return (
    <div id="projects">
      <div className="filter-buttons">
        <h2 className="titlex">My Projects</h2>
        <button onClick={() => setFilter('All')}>All</button>
        <button onClick={() => setFilter('Full Stack')}>Full Stack</button>
        <button onClick={() => setFilter('Backend')}>Backend</button>
        <button onClick={() => setFilter('Mobile')}>Mobile</button>
        <button onClick={() => setFilter('Frontend Developer')}>Frontend</button>
        <button onClick={() => setFilter('Volunteer')}>Volunteer</button>
      </div>
      <div className="projects-container">
        {filteredProjects.map(project => (
          <div className="project-card" key={project.title}>
            <img src={project.image} alt={project.title} />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <div className="technologies">
              {project.technologies.map(tech => (
                <span key={tech}>{tech}</span>
              ))}
            </div>
            <hr className="hr-divider" />
            <div className="links">
              {project.github && (
                <a href={project.github} target="_blank" rel="noopener noreferrer">GitHub</a>
              )}
              {project.website && (
                <a href={project.website} target="_blank" rel="noopener noreferrer">Website</a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css'; // Import the CSS file
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome CSS

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_gvht2p4', 'template_rqehy8n', e.target, 'Stiu1FZwkAYqBsTdW')
      .then((result) => {
        console.log(result.text);
        // Add any success message or redirect here
      }, (error) => {
        console.log(error.text);
        // Add any error message here
      });
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div id="contact" className="container">
      <div className="contact-section">
        <h2>Contact Me</h2>
        <div className="contact-item">
          <i className="fas fa-envelope"></i>
          <p>Email: <a href="mailto:honourkorkmaz@gmail.com">honourkorkmaz@gmail.com</a></p>
        </div>
        <div className="contact-item">
          <i className="fas fa-phone"></i>
          <p>Phone Number: <span>647 607 3838</span></p>
        </div>
        <div className="contact-item">
          <i className="fab fa-github"></i>
          <p>Github: <a href="https://github.com/ozXoz" target="_blank" rel="noopener noreferrer">github.com/ozXoz</a></p>
        </div>
        <div className="contact-item">
          <i className="fab fa-linkedin"></i>
          <p>LinkedIn: <a href="https://www.linkedin.com/in/onur-korkmaz/" target="_blank" rel="noopener noreferrer">linkedin.com/in/onur-korkmaz/</a></p>
        </div>
      </div>
      <div className="form-section">
        <h2>Contact Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <input type="submit" value="Submit" className="btn" />
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
